<template>
  <div>
    <div class="mx-2">
      <!-- <b-breadcrumb
        class="breadcrumb pb-2 pt-1 noprint"
        :items="breadcrumb_items"
      ></b-breadcrumb> -->
      <div style="display: flex">
        <div class="col" style="text-align: left">
          <button
            v-can="'cideu.add_acttype'"
            v-if="allows_crud"
            class="btn btn-secondary btn-sm"
            @click="$bvModal.show(`new-act-type-modal`)"
          >
            Agregar
            {{ $getVisibleNames("cideu.acttype", false, "Tipo de Acta") }}
            <!-- Tipo de Acta -->
          </button>
        </div>
      </div>
      <h3 class="mt-3">
        {{ $getVisibleNames("cideu.acttype", false, "Tipo de Acta") }}
        <AlternativeNameButton
          :table_name="'cideu.acttype'"
          :allows_crud="allows_crud"
        ></AlternativeNameButton>
      </h3>
      <GenericBTable
        v-if="searchActType.length > 0"
        :items="act_types"
        :pagination="act_types.length"
        :fields="actTypesFields"
        :filter="input_search"
        :show_pagination="true"
        :search_filter="true"
        :columns_display="true"
        :allows_crud="allows_crud"
        :display_id="display_id"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
      >
        <template> </template>
        <template v-if="allows_crud" #cell(actions)="row">
          <button-edit
            @click="$bvModal.show(`edit-act-type-modal-${row.item.id}`)"
            v-b-tooltip.v-secondary.noninteractive="
              `Editar ${$getVisibleNames(
                'cideu.acttype',
                false,
                'Tipo de Acta'
              )}`
            "
          ></button-edit>
          <button-delete
            @click="askForDeleteActType(row.item.id)"
            v-b-tooltip.v-secondary.noninteractive="
              `Eliminar ${$getVisibleNames(
                'cideu.acttype',
                false,
                'Tipo de Acta'
              )}`
            "
          ></button-delete>
          <b-modal
            :id="`edit-act-type-modal-${row.item.id}`"
            :title="`Editar ${$getVisibleNames(
              'cideu.acttype',
              false,
              'Tipo de Acta'
            )}`"
            size="md"
            hide-footer
          >
            <ActTypeForm
              :actType="row.item"
              @updated="slotUpdatedActType"
              :institution_id="row.item.school"
              :show_title="false"
            ></ActTypeForm>
          </b-modal>
        </template>
      </GenericBTable>
      <div v-else>
        <br />
        <strong
          >No se a encontrado ninguna
          {{
            $getVisibleNames("cideu.acttype", false, "Tipo de Acta")
          }}.</strong
        >
      </div>
    </div>
    <b-modal
      :id="`new-act-type-modal`"
      :hide-footer="true"
      :title="`Crear ${$getVisibleNames(
        'cideu.acttype',
        false,
        'Tipo de Acta'
      )}`"
      size="md"
    >
      <ActTypeForm
        @created="slotCreatedActType"
        :institution_id="this.user.school"
      ></ActTypeForm>
    </b-modal>
  </div>
</template>
<script>
// import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "@/utils/utils";

export default {
  name: "ActTypeView",
  components: {
    ActTypeForm: () =>
      import("@/components/mesh/CurricularResources/ActType/ActTypeForm"),
    GenericBTable: () => import("@/components/reusable/GenericBTable"),
    AlternativeNameButton: () =>
      import("@/components/reusable/AlternativeNameButton"),
  },
  props: {
    input_search: {
      type: String,
      default: "",
    },
    display_id: {},
  },
  data() {
    return {
      // breadcrumb_items: [],
      act_types: [],
      sortBy: "",
      sortDesc: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
    allows_crud() {
      const has_permission = this.$hasPermissions("cideu.add_acttype");
      if (has_permission) return has_permission;
      else return false;
    },
    searchActType: function () {
      return this.act_types
        .filter((item) => {
          return (
            item.name.toLowerCase().includes(this.input_search.toLowerCase()) ||
            item.description
              .toLowerCase()
              .includes(this.input_search.toLowerCase())
          );
        })
        .sort(function (a, b) {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        });
    },
    actTypesFields() {
      if (!this.allows_crud)
        return this.actTypes_fields.filter(
          (field) => !field.requires_allows_crud
        );
      else return this.actTypes_fields;
    },
    actTypes_fields() {
      return [
        {
          key: "name",
          label: String(
            this.$getVisibleNames("cideu.acttype", true, "Tipos de Actas")
          ),
          sortable: true,
          tdClass: "text-left",
          thStyle: { background: "var(--primary-color) !important" },
          display_column: true,
        },
        {
          key: "description",
          label: "Descripción",
          sortable: true,
          tdClass: "text-left",
          thStyle: { background: "var(--primary-color) !important" },
          display_column: true,
        },
        {
          key: "actions",
          label: "",
          display_column: true,
          thClass: "noprint",
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "noprint",
        },
      ];
    },
  },
  methods: {
    askForDeleteActType(act_type_id) {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "cideu.acttype",
          false,
          "Tipo de Acta"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$restful.Delete(`/cideu/act-type/${act_type_id}/`).then(() => {
            toast(
              String(
                this.$getVisibleNames("cideu.acttype", false, "Tipo de Acta") +
                  " eliminada."
              )
            );
          });
          let index = this.act_types.findIndex((x) => x.id == act_type_id);
          if (index != -1) this.act_types.splice(index, 1);
        }
      });
    },
    mutateActType(act_type) {
      let index = this.act_types.findIndex((x) => x.id == act_type.id);
      if (index != -1) this.act_types.splice(index, 1, act_type);
      else this.act_types.push(act_type);
    },
    slotCreatedActType(act_type) {
      this.mutateActType(act_type);
      this.$bvModal.hide("new-act-type-modal");
    },
    slotUpdatedActType(act_type) {
      this.mutateActType(act_type);
      this.$bvModal.hide(`edit-act-type-modal-${act_type.id}`);
    },
    fetchActTypes() {
      this.$restful.Get("/cideu/act-type/").then((response) => {
        this.act_types = response;
      });
    },
  },
  mounted() {
    // this.breadcrumb_items.push({
    //   text: "Definiciones Curriculares",
    //   active: true,
    // });
    // this.breadcrumb_items.push({
    //   text: this.$getVisibleNames("cideu.acttype", false, "Tipo de Acta"),
    //   active: true,
    // });
  },
  created() {
    this.fetchActTypes();
  },
};
</script>

<style scoped>
th {
  background-color: var(--primary-color);
  border-radius: 6px;
  box-shadow: 1px 1px 3px rgb(72, 72, 72);
}

.title-header {
  padding: 0;
  margin-top: auto;
}
.search-modality {
  max-width: 50rem;
  margin-left: auto;
  margin-right: 18.5%;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}
.edit-section {
  margin-top: -2%;
}
.breadcrumb {
  background: none;
  padding: 0;
  margin: 0;
  margin-left: 2.8em !important;
}
.breadcrumb-item.active {
  color: #2f9ae1;
}
.breadcrumb > li + li:before {
  color: #2f9ae1;
  content: "❯ ";
  padding: 0;
  padding-right: 0.65em;
}
</style>