var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mx-2"},[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"col",staticStyle:{"text-align":"left"}},[(_vm.allows_crud)?_c('button',{directives:[{name:"can",rawName:"v-can",value:('cideu.add_acttype'),expression:"'cideu.add_acttype'"}],staticClass:"btn btn-secondary btn-sm",on:{"click":function($event){return _vm.$bvModal.show(`new-act-type-modal`)}}},[_vm._v(" Agregar "+_vm._s(_vm.$getVisibleNames("cideu.acttype", false, "Tipo de Acta"))+" ")]):_vm._e()])]),_c('h3',{staticClass:"mt-3"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames("cideu.acttype", false, "Tipo de Acta"))+" "),_c('AlternativeNameButton',{attrs:{"table_name":'cideu.acttype',"allows_crud":_vm.allows_crud}})],1),(_vm.searchActType.length > 0)?_c('GenericBTable',{attrs:{"items":_vm.act_types,"pagination":_vm.act_types.length,"fields":_vm.actTypesFields,"filter":_vm.input_search,"show_pagination":true,"search_filter":true,"columns_display":true,"allows_crud":_vm.allows_crud,"display_id":_vm.display_id,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([(_vm.allows_crud)?{key:"cell(actions)",fn:function(row){return [_c('button-edit',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
            `Editar ${_vm.$getVisibleNames(
              'cideu.acttype',
              false,
              'Tipo de Acta'
            )}`
          ),expression:"\n            `Editar ${$getVisibleNames(\n              'cideu.acttype',\n              false,\n              'Tipo de Acta'\n            )}`\n          ",modifiers:{"v-secondary":true,"noninteractive":true}}],on:{"click":function($event){return _vm.$bvModal.show(`edit-act-type-modal-${row.item.id}`)}}}),_c('button-delete',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
            `Eliminar ${_vm.$getVisibleNames(
              'cideu.acttype',
              false,
              'Tipo de Acta'
            )}`
          ),expression:"\n            `Eliminar ${$getVisibleNames(\n              'cideu.acttype',\n              false,\n              'Tipo de Acta'\n            )}`\n          ",modifiers:{"v-secondary":true,"noninteractive":true}}],on:{"click":function($event){return _vm.askForDeleteActType(row.item.id)}}}),_c('b-modal',{attrs:{"id":`edit-act-type-modal-${row.item.id}`,"title":`Editar ${_vm.$getVisibleNames(
            'cideu.acttype',
            false,
            'Tipo de Acta'
          )}`,"size":"md","hide-footer":""}},[_c('ActTypeForm',{attrs:{"actType":row.item,"institution_id":row.item.school,"show_title":false},on:{"updated":_vm.slotUpdatedActType}})],1)]}}:null],null,true)},[void 0],2):_c('div',[_c('br'),_c('strong',[_vm._v("No se a encontrado ninguna "+_vm._s(_vm.$getVisibleNames("cideu.acttype", false, "Tipo de Acta"))+".")])])],1),_c('b-modal',{attrs:{"id":`new-act-type-modal`,"hide-footer":true,"title":`Crear ${_vm.$getVisibleNames(
      'cideu.acttype',
      false,
      'Tipo de Acta'
    )}`,"size":"md"}},[_c('ActTypeForm',{attrs:{"institution_id":this.user.school},on:{"created":_vm.slotCreatedActType}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }